import React, { useEffect, useState } from 'react';
import '../styles/orders.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const DetailOrder: React.FC = () => {

  const { id } = useParams<{ id: string }>();
  const [orders, setOrders] = useState<any>()

  useEffect(() => {
    const fetchData = async () => {
      try {

        const value = localStorage.getItem('authToken');
        console.log(value);
        const response = await axios.get(
          'https://melist.backendtropa.com.br//admin/order/detail/' + id,
          {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + value },
          }
        );
        setOrders(response?.data)
      } catch (error: any) {
        console.log(error.toString())
      }
    };

    fetchData();
  }, []);

  async function generateCsv(){
    try {

      const value = localStorage.getItem('authToken');
      console.log(value);
      const response = await axios.get(
        'https://melist.backendtropa.com.br//admin/order/csv/' + id,
        {
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + value },
        }
      );
      forceDownload(response?.data?.csv, 'pedido.csv')
    } catch (error: any) {
      console.log(error.toString())
    }
  }

  const forceDownload = async (fileUrl: string, fileName: string) => {
    try {
      // Faz o download do arquivo como Blob
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Falha ao baixar o arquivo');
      }
  
      const blob = await response.blob();
  
      // Cria um link temporário para o download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName; // Define o nome do arquivo
      link.click();
  
      // Remove o link temporário
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao forçar download:', error);
    }
  };

  return (
    <div className='pedidos'>

      <div className="header">
        <div className="header-content">
          <a href="/open-orders">
            <button className="back-button"><img src='/images/parabaixo.png' alt='save' /></button>
          </a>
          
          <div className='tit'>
            <span className="title">{orders?.owner?.name}</span>
            <span className="details">{orders?.items.length} itens - {orders?.market?.name} • {orders?.createdAt && new Date(orders?.createdAt).toLocaleString()}</span>
          </div>
        </div>
        <button className="export-button" onClick={() => generateCsv()}>
          Exportar CSV
          <img src='/images/save.png' alt='save' />
        </button>

      </div>

      <table>
        <thead>
          <tr>
            <th>Imagem</th>
            <th>EAN/GTIN</th>
            <th>Descrição</th>
            <th>Qtde.</th>
            <th>Seção</th>
            <th>Marca</th>
          </tr>
        </thead>
        <tbody>
          {orders?.items.map((item: any, index: any) => (
            <tr key={index}>
              <td>{item?.product?.image ? <img alt="imagem" width={50} src={item.product.image} /> : ''}</td>
              <td><b style={{ color: '#000' }}>{item?.product?.gtin}</b></td>
              <td>{item?.product?.title}</td>
              <td>{item?.qty}</td>
              <td>{item?.product.product_Sections[0]?.section?.title}</td>
              <td>{item?.product?.brand}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DetailOrder;
